<template>

    <div style="margin-top: 40px;padding-bottom: 30px">
        <div class="mt-tabs-hd">
            <ul class="mt-tabs-list">
                <li class="mt-tabs-item" :class="{'is-active':isShow}" @click="studentShow"><a id="personal">会员版</a>
                </li>

                <li class="mt-tabs-item " :class="{'is-active':!isShow}" @click="teacherShow"><a id="organization">教师版/校园版</a>
                </li>
            </ul>
        </div>
        <div class="page" name="first" v-show="studentPrise">
            <div class="big-title">全站试题实时更新 | 历年真题VIP免费作答</div>
            <div style="color: red;text-align: center;margin-top: -10px">
                会员版-历年真题、模拟题、练习题 每日不定时更新 欢迎投稿 投稿送会员
            </div>
            <div
                    style="display: flex;flex-wrap: nowrap;flex-direction: row;justify-content: space-between;margin-left: -200px">
                <selfVipCard :isRecommend="'selfVipCard'" :vipType="typeInfo1"></selfVipCard>
                <selfVipCard :isRecommend="'selfVipCard'" :vipType="typeInfo2"></selfVipCard>
                <selfVipCard :isRecommend="'selfVipCard recommend'" :vipType="typeInfo3"></selfVipCard>
                <selfVipCard :isRecommend="'selfVipCard'" :vipType="typeInfo4"></selfVipCard>
            </div>

            <!--<div>
                <p class="title">他们都选择了我们</p>
                <div class="message-board-total">
                    <div class="banner-container">
                        <div
                                class="banner"
                                v-for="(banner, index) in banners"
                                :key="index"
                                :style="{ 'animation-delay':  randomNumber[index+1] + 's' }"
                        >
                            {{ banner.text }}
                        </div>
                    </div>
                    <div class="banner-container">
                        <div
                                class="banner"
                                v-for="(banner, index) in banners1"
                                :key="index"
                                :style="{ 'animation-delay': randomNumber[index+3] + 's' }"
                        >
                            {{ banner.text }}
                        </div>
                    </div>
                    <div class="banner-container">
                        <div
                                class="banner"
                                v-for="(banner, index) in banners2"
                                :key="index"
                                :style="{ 'animation-delay':  randomNumber[index] + 's' }"
                        >
                            {{ banner.text }}
                        </div>
                    </div>
                    <div class="banner-container">
                        <div
                                class="banner"
                                v-for="(banner, index) in banners3"
                                :key="index"
                                :style="{ 'animation-delay': randomNumber[index+2] + 's' }"
                        >
                            {{ banner.text }}
                        </div>
                    </div>
                    <div class="banner-container">
                        <div
                                class="banner"
                                v-for="(banner, index) in banners4"
                                :key="index"
                                :style="{ 'animation-delay':  randomNumber[index+4] + 's' }"
                        >
                            {{ banner.text }}
                        </div>
                    </div>
                </div>
            </div>-->

            <div>
                <p class="title">一套属于我们自己的在线考试辅导平台</p>
                <div class="vip_detail_cardList">
                    <div class="vip_detail_item">
                        <span class="vip_detail_title__y">历年真题</span>
                        <span class="vip_detail_content">NOIP、蓝桥杯、等级考试等历年试卷真题，打造VIP专属题库</span>
                    </div>
                    <div class="vip_detail_item">
                        <span class="vip_detail_title__y">模拟测试</span>
                        <span class="vip_detail_content">覆盖包含Scratch、C++、Python等多种语言，更贴近学习所需</span>
                    </div>
                    <div class="vip_detail_item">
                        <span class="vip_detail_title__y">不止提升</span>
                        <span class="vip_detail_content">累计合作百位专业教师，严控试题普及、考点、难易、严谨</span>
                    </div>
                    <div class="vip_detail_item">
                        <span class="vip_detail_title__y">引领行业</span>
                        <span class="vip_detail_content">甄选成套试题，历年真题、模拟试题、专属练习，知识绝不错过</span>
                    </div>
                </div>
            </div>

        </div>

        <div class="page" name="second" v-show="teacherPrise">
            <div class="big-title">学练考评一站式在线考试系统 | 让考试更简单</div>
            <div style="color: red;text-align: center;margin-top: -10px">
                教师版/校园版-专注青少年编程在线考试、刷题、模拟、练习，稳定高效，操作更简单 《体验账号：teacher &nbsp;密码：teacher》
            </div>

            <div style="display: flex;flex-wrap: nowrap;flex-direction: row;justify-content: space-between;margin-left: -200px">
                <selfTeacherVipCard :isRecommend="'selfVipCard'" :vipType="typeInfo1"></selfTeacherVipCard>
                <selfTeacherVipCard :isRecommend="'selfVipCard'" :vipType="typeInfo2"></selfTeacherVipCard>
                <selfTeacherVipCard :isRecommend="'selfVipCard recommend'" :vipType="typeInfo3"></selfTeacherVipCard>
                <selfTeacherVipCard :isRecommend="'selfVipCard'" :vipType="typeInfo5"></selfTeacherVipCard>
            </div>


            <div class="response-container">
                <p class="title">教师版/校园版平台-简要介绍</p>
                <div class="w-teacher-content-bg">
                    <div class="w-teacher-content">
                        <div class="w-teacher-left-panel">
                            <ul class="tab-list">
                                <div class="tab-active hidden-xs"
                                     :style="{ transform: 'translateY('+ translateY + 'px)' }">
                                </div>

                                <li class="tab-item" v-for="item in teacher_content"
                                    @click="selectIndex(item.index,item.translateY)">
                                    <h1 class="tab-item__title">{{item.title}}</h1>
                                    <p class="tab-item__desc hidden-xs">{{item.des}}</p>
                                </li>

                            </ul>
                        </div>
                        <div class="w-teacher-right-panel">
                            <div class="w-teacher-info">
                                <a class="w-teacher-info__title">
                                    <span>{{teacher_content[tabIndex].detail.title}}</span>
                                </a>
                                <p class="w-teacher-info__hint">{{teacher_content[tabIndex].detail.info}}</p>
                                <ul class="w-teacher-info__desc">
                                    <li class="w-teacher-info__desc-item"
                                        v-for=" item in teacher_content[tabIndex].detail.subList">
                                        <p class="w-teacher-info__desc-name">{{item.name}}</p>
                                        <p class="w-teacher-info__desc-hint">{{item.des}}</p>
                                    </li>
                                </ul>
                                <button type="button" class="w-teacher-info__btn oui-button" @click="goToWancodeVip">
                                    立即体验
                                </button>
                                <img src="../../assets/teacher_vip.jpg" class="w-teacher-info__img">
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div>
                <p class="title">一套属于我们自己的在线考试辅导平台</p>
                <div class="vip_detail_cardList">
                    <div class="vip_detail_item">
                        <span class="vip_detail_title__y">学生考试</span>
                        <span class="vip_detail_content">布置学生作业任务、在线题库刷题等、支持错题练习</span>
                    </div>
                    <div class="vip_detail_item">
                        <span class="vip_detail_title__y">无纸化考核</span>
                        <span class="vip_detail_content">在线考试、无纸化考核、系统安全稳定、页面流畅不卡顿</span>
                    </div>
                    <div class="vip_detail_item">
                        <span class="vip_detail_title__y">历年真题</span>
                        <span class="vip_detail_content"> NOIP、蓝桥杯、等级考试等历年试卷真题，打造VIP专属题库</span>
                    </div>
                    <div class="vip_detail_item">
                        <span class="vip_detail_title__y">模拟测试</span>
                        <span class="vip_detail_content">覆盖包含Scratch、C++、Python等多种语言，更贴近学习所需</span>
                    </div>
                    <div class="vip_detail_item">
                        <span class="vip_detail_title__y">不止提升</span>
                        <span class="vip_detail_content">累计合作百位专业教师，严控试题普及、考点、难易、严谨</span>
                    </div>
                    <div class="vip_detail_item">
                        <span class="vip_detail_title__y">引领行业</span>
                        <span class="vip_detail_content">甄选成套试题，历年真题、模拟试题、专属练习，知识绝不错过</span>
                    </div>
                </div>
            </div>

        </div>

        <div class="vip-desc page">
            <div style="text-align: left;font-size: 16px;margin-top: 50px;font-weight:900;"
                 class="el-icon-document"> 会员开通说明
            </div>
            <div style="text-align: left;font-size: 16px;margin-top: 20px"> 当您成功开通本站尊贵会员，即刻享有对应级别的会员权限，全站全科目题库答案均可畅览。会员权益如下：
            </div>
            <div style="text-align: left;font-size: 16px;margin-top: 20px"> 1、我们为您提供高品质的会员题库资源，并承诺免费持续更新，确保您始终掌握最新、最全面的学习资料。</div>

            <div style="text-align: left;font-size: 16px;margin-top: 20px">
                2、会员开通遵循自愿原则，我们绝不施加任何强迫。如遇任何问题，欢迎随时联系客服，我们将竭诚为您解决。
            </div>

            <div style="text-align: left;font-size: 16px;margin-top: 20px">
                3、本站专注于考级竞赛题目信息的收集与整理，旨在为您的学习之旅提供坚实有力的支持。
            </div>

            <div style="text-align: left;font-size: 16px;margin-top: 20px"> 4、若您在解题过程中遇到难题，可随时联系客服，享受一对一试卷深度讲解服务（此服务需另行收费）。</div>
            <div style="text-align: left;font-size: 16px;margin-top: 20px"> 特别提醒：本产品为虚拟产品，付款后不支持退款。本站保留对会员服务的最终解释权。</div>
        </div>
        <a class="join-vip" @click="scrollToTop">立即加入，在线做练习</a>
    </div>
</template>

<script>
    import selfVipCard from "./components/selfVipCard.vue"
    import selfTeacherVipCard from "./components/selfTeacherVipCard";

    export default {
        components: {
            selfTeacherVipCard,
            selfVipCard
        },
        data() {
            return {
                text: 'Hello, Vue.js!',
                activeName: 'first',
                studentPrise: true,
                teacherPrise: false,
                isShow: true,
                typeInfo1: {
                    type: 4
                },
                typeInfo2: {
                    type: 7
                },
                typeInfo3: {
                    type: 5
                },
                typeInfo4: {
                    type: 1
                },
                typeInfo5: {
                    type: 6
                },
                tabIndex: 0,
                translateY: 0,
                teacher_content: [
                    {
                        index: 0,
                        translateY: 0,
                        title: '学生管理',
                        des: '教学机构管理设置',
                        detail: {
                            title: '机构管理',
                            info: '机构信息设置 | 添加教师 | 学生信息录入 | 班级分配 | 课时统计',

                            subList: [
                                {name: '添加学生账号', des: '学生信息录入 | 班级分配 | 信息审核与确认'},
                                {name: '管理班级', des: '根据教学需求，创建新的班级，并设置班级名称、年级、学科等信息'},
                                {name: '课时统计', des: '课时数据录入 | 统计与分析 | 绩效考核'}
                            ]
                        },
                        img: '../../assets/teacher_vip.jpg'
                    }, {
                        index: 1,
                        translateY: 96,
                        title: '作业管理',
                        des: '布置学生作业任务',
                        detail: {
                            title: '作业管理',
                            info: '布置学生作业任务 | 在线题库刷题等 | 错题自动记录',
                            subList: [
                                {name: '布置学生作业任务', des: '根据教学目标和学生的实际水平，布置具有针对性、适宜性和有效性的作业'},
                                {name: '在线题库刷题', des: '学生可以根据自己的学习进度和兴趣，选择适合自己的刷题方式和难度'},
                                {name: '错题自动记录', des: '系统自动记录学生在刷题过程中做错的题目，生成专属的错题集'}
                            ]
                        },
                        img: '../../assets/teacher_vip.jpg'
                    }, {
                        index: 2,
                        translateY: 192,
                        title: '课时管理',
                        des: '统计和分析课时',
                        detail: {
                            title: '课时统计',
                            info: '课时数据录入 | 统计与分析 | 绩效考核',
                            subList: [
                                {name: '课时数据录入', des: '可以录入教师的课时数据，如上课时间、课程名称、授课班级等'},
                                {name: '统计与分析', des: '系统可以自动统计和分析教师/学生的课时情况'},
                                {name: '绩效考核', des: '课时统计数据帮助机构更好地评估教师的教学效果和工作量。'}
                            ]
                        },
                        img: '../../assets/teacher_vip.jpg'
                    }, {
                        index: 3,
                        translateY: 288,
                        title: '真题演练',
                        des: '检验学习提高应试',
                        detail: {
                            title: '真题演练',
                            info: '历年真题、模拟题、练习题实时更新',
                            subList: [
                                {name: '历年真题', des: '包含电子学会、CCF GESP、信奥赛等多个领域，实时更新'},
                                {name: '检验学习效果', des: '真题演练可以帮助学生检验自己的学习效果，了解自己对知识点的掌握程度'},
                                {name: '了解考试趋势', des: '通过分析真题，学生可以了解考试的出题趋势和重点，从而更有针对性地进行复习'}
                            ]
                        },
                        img: '../../assets/teacher_vip.jpg'
                    }, {
                        index: 4,
                        translateY: 384,
                        title: '模拟测试',
                        des: '模拟测试检验成果',
                        detail: {
                            title: '模拟测试',
                            info: '熟悉考试流程、题型和难度，提高应试能力和自信心',
                            subList: [
                                {name: '模拟考试', des: '覆盖分类包含Scratch、C++、Python等多个领域，更贴近实际学习所需'},
                                {name: '评估学习成果', des: '通过模拟测试，教师可以了解学生对所学知识的掌握情况，从而调整教学策略和方法'},
                                {name: '检验教学效果', des: '教师可以通过分析学生的模拟测试成绩，了解教学效果，以便进行针对性的改进'}
                            ]
                        },
                        img: '../../assets/teacher_vip.jpg'
                    }, {
                        index: 5,
                        translateY: 480,
                        title: '录题组卷',
                        des: '精心挑选和组合试题',
                        detail: {
                            title: '录题组卷',
                            info: '甄选成套试题，历年真题、模拟试题、专属练习，知识绝不错过',
                            subList: [
                                {name: '自定义录题', des: '根据教学需求，自行录入和编辑题目，从而构建自己的题库'},
                                {name: '自定义组卷', des: '根据需求，自由选择题目类型和难度，形成个性化的试卷'},
                                {name: '个性化教学', des: '学生通过练习教师根据他们的学习需求定制的试卷，可以更有针对性地进行复习，提高学习效率'}
                            ]
                        },
                        img: '../../assets/teacher_vip.jpg'
                    }
                ],
                randomNumber: [0.3, 0.6, 0.2, 0.5, 0.4, 0.5],
                banners: [
                    {text: '178****1809 购买了月会员'},
                    {text: '151****4790 购买了月会员'},
                    {text: '182****2593 购买了季度会员'},
                    {text: '147****1710 购买了月会员'},
                    {text: '151****5542 购买了月会员'},
                    {text: '138****9377 购买了月会员'},
                    {text: '157****9127 购买了季度会员'},
                    {text: '157****5476 购买了月会员'},
                    {text: '182****5115 购买了尊享永久会员'},
                    {text: '157****5894 购买了季度会员'},
                    {text: '182****0151 购买了PLUS年会员'},
                    {text: '135****1693 购买了PLUS年会员'},
                    {text: '159****9005 购买了季度会员'},
                    {text: '159****9035 购买了季度会员'},
                    {text: '136****6432 购买了尊享永久会员'},
                    {text: '135****3616 购买了月会员'},
                    {text: '139****8697 购买了月会员'},
                    {text: '178****1527 购买了月会员'},
                    {text: '188****7904 购买了尊享永久会员'},
                    {text: '158****9765 购买了PLUS年会员'},
                    {text: '172****8756 购买了PLUS年会员'},
                    {text: '137****6365 购买了尊享永久会员'},
                    {text: '159****5312 购买了月会员'},
                    {text: '139****4187 购买了月会员'},
                    {text: '172****6468 购买了PLUS年会员'},
                    {text: '178****2591 购买了PLUS年会员'},
                    {text: '187****5136 购买了PLUS年会员'},
                    {text: '165****4894 购买了PLUS年会员'},
                    {text: '187****7498 购买了月会员'},
                    {text: '134****3244 购买了月会员'},
                    {text: '147****3288 购买了月会员'},
                    {text: '159****7834 购买了月会员'},
                    {text: '178****8417 购买了季度会员'},
                    {text: '150****0822 购买了季度会员'},
                    {text: '150****6779 购买了月会员'},
                    {text: '159****3025 购买了月会员'},
                    {text: '151****0985 购买了月会员'},
                    {text: '157****2437 购买了季度会员'},
                    {text: '178****7013 购买了季度会员'},
                    {text: '134****6530 购买了季度会员'},
                    {text: '134****5583 购买了尊享永久会员'},
                    {text: '165****7308 购买了月会员'},
                    {text: '178****4379 购买了月会员'},
                    {text: '159****7001 购买了月会员'},
                    {text: '184****3361 购买了月会员'},
                    {text: '165****0950 购买了月会员'},
                    {text: '135****2011 购买了尊享永久会员'},
                    {text: '157****5744 购买了月会员'},
                    {text: '150****3109 购买了月会员'},
                    {text: '183****9313 购买了月会员'},
                    {text: '134****4838 购买了尊享永久会员'},
                    {text: '182****6537 购买了月会员'},
                    {text: '157****8342 购买了月会员'},
                    {text: '152****1342 购买了尊享永久会员'},
                    {text: '151****9732 购买了尊享永久会员'},
                    {text: '159****8288 购买了月会员'},
                    {text: '159****8580 购买了月会员'},
                    {text: '184****7295 购买了月会员'},
                    {text: '135****5344 购买了月会员'},
                    {text: '157****9511 购买了月会员'},
                    {text: '165****6837 购买了尊享永久会员'},
                    {text: '178****8810 购买了尊享永久会员'},
                    {text: '198****1202 购买了月会员'},
                    {text: '198****8845 购买了月会员'},
                    {text: '172****1309 购买了月会员'},
                    {text: '182****8911 购买了月会员'},
                    {text: '147****2681 购买了季度会员'},
                    {text: '135****6485 购买了季度会员'},
                    {text: '165****0268 购买了季度会员'},
                    {text: '159****7243 购买了月会员'},
                    {text: '137****6969 购买了尊享永久会员'},
                    {text: '158****7032 购买了尊享永久会员'},
                    {text: '137****7850 购买了季度会员'},
                    {text: '150****7383 购买了季度会员'},
                    {text: '147****7091 购买了季度会员'},
                    {text: '165****2491 购买了月会员'},
                    {text: '182****4525 购买了月会员'},
                    {text: '184****9133 购买了月会员'},
                    {text: '134****3737 购买了季度会员'},
                    {text: '165****5844 购买了季度会员'},
                    {text: '138****4335 购买了月会员'},
                    {text: '184****6222 购买了月会员'},
                    {text: '188****7416 购买了尊享永久会员'},
                    {text: '157****7078 购买了月会员'},
                    {text: '178****8712 购买了月会员'},
                    {text: '184****5718 购买了PLUS年会员'},
                    {text: '151****5800 购买了PLUS年会员'},
                    {text: '198****7142 购买了月会员'},
                    {text: '157****0204 购买了月会员'},
                    {text: '184****8291 购买了PLUS年会员'},
                    {text: '135****9257 购买了月会员'},
                    {text: '136****8996 购买了月会员'},
                    {text: '147****5732 购买了月会员'},
                    {text: '134****7442 购买了月会员'},
                    {text: '198****5408 购买了PLUS年会员'},
                    {text: '151****6435 购买了PLUS年会员'},
                    {text: '198****6479 购买了月会员'},
                    {text: '187****5051 购买了月会员'},
                    {text: '139****5340 购买了月会员'}

                ],
                banners1: [
                    {text: '165****6837 购买了尊享永久会员'},
                    {text: '178****8810 购买了尊享永久会员'},
                    {text: '198****1202 购买了月会员'},
                    {text: '198****8845 购买了月会员'},
                    {text: '172****1309 购买了月会员'},
                    {text: '182****8911 购买了月会员'},
                    {text: '147****2681 购买了季度会员'},
                    {text: '135****6485 购买了季度会员'},
                    {text: '165****0268 购买了季度会员'},
                    {text: '159****7243 购买了月会员'},
                    {text: '137****6969 购买了尊享永久会员'},
                    {text: '158****7032 购买了尊享永久会员'},
                    {text: '137****7850 购买了季度会员'},
                    {text: '150****7383 购买了季度会员'},
                    {text: '147****7091 购买了季度会员'},
                    {text: '165****2491 购买了月会员'},
                    {text: '182****4525 购买了月会员'},
                    {text: '184****9133 购买了月会员'},
                    {text: '134****3737 购买了季度会员'},
                    {text: '165****5844 购买了季度会员'},
                    {text: '138****4335 购买了月会员'},
                    {text: '184****6222 购买了月会员'},
                    {text: '188****7416 购买了尊享永久会员'},
                    {text: '157****7078 购买了月会员'},
                    {text: '178****8712 购买了月会员'},
                    {text: '184****5718 购买了PLUS年会员'},
                    {text: '151****5800 购买了PLUS年会员'},
                    {text: '198****7142 购买了月会员'},
                    {text: '157****0204 购买了月会员'},
                    {text: '184****8291 购买了PLUS年会员'},
                    {text: '135****9257 购买了月会员'},
                    {text: '136****8996 购买了月会员'},
                    {text: '147****5732 购买了月会员'},
                    {text: '134****7442 购买了月会员'},
                    {text: '198****5408 购买了PLUS年会员'},
                    {text: '151****6435 购买了PLUS年会员'},
                    {text: '198****6479 购买了月会员'},
                    {text: '187****5051 购买了月会员'},
                    {text: '139****5340 购买了月会员'}
                ],
                banners2: [

                    {text: '159****3025 购买了月会员'},
                    {text: '151****0985 购买了月会员'},
                    {text: '157****2437 购买了季度会员'},
                    {text: '178****7013 购买了季度会员'},
                    {text: '134****6530 购买了季度会员'},
                    {text: '134****5583 购买了尊享永久会员'},
                    {text: '165****7308 购买了月会员'},
                    {text: '178****4379 购买了月会员'},
                    {text: '159****7001 购买了月会员'},
                    {text: '184****3361 购买了月会员'},
                    {text: '165****0950 购买了月会员'},
                    {text: '135****2011 购买了尊享永久会员'},
                    {text: '157****5744 购买了月会员'},
                    {text: '150****3109 购买了月会员'},
                    {text: '183****9313 购买了月会员'},
                    {text: '134****4838 购买了尊享永久会员'},
                    {text: '182****6537 购买了月会员'},
                    {text: '157****8342 购买了月会员'},
                    {text: '152****1342 购买了尊享永久会员'},
                    {text: '151****9732 购买了尊享永久会员'},
                    {text: '159****8288 购买了月会员'},
                    {text: '159****8580 购买了月会员'},
                    {text: '184****7295 购买了月会员'},
                    {text: '135****5344 购买了月会员'},
                    {text: '157****9511 购买了月会员'},
                    {text: '165****6837 购买了尊享永久会员'}
                ],
                banners3: [
                    {text: '165****4894 购买了PLUS年会员'},
                    {text: '187****7498 购买了月会员'},
                    {text: '134****3244 购买了月会员'},
                    {text: '147****3288 购买了月会员'},
                    {text: '159****7834 购买了月会员'},
                    {text: '178****8417 购买了季度会员'},
                    {text: '150****0822 购买了季度会员'},
                    {text: '150****6779 购买了月会员'},
                    {text: '159****3025 购买了月会员'},
                    {text: '151****0985 购买了月会员'},
                    {text: '157****2437 购买了季度会员'},
                    {text: '178****7013 购买了季度会员'},
                    {text: '134****6530 购买了季度会员'},
                    {text: '134****5583 购买了尊享永久会员'},
                    {text: '165****7308 购买了月会员'},
                    {text: '178****4379 购买了月会员'},
                    {text: '159****7001 购买了月会员'},
                    {text: '184****3361 购买了月会员'},
                    {text: '165****0950 购买了月会员'},
                    {text: '135****2011 购买了尊享永久会员'},
                    {text: '157****5744 购买了月会员'},
                    {text: '150****3109 购买了月会员'},
                    {text: '183****9313 购买了月会员'},
                    {text: '134****4838 购买了尊享永久会员'},
                    {text: '182****6537 购买了月会员'},
                    {text: '157****8342 购买了月会员'},
                    {text: '152****1342 购买了尊享永久会员'},
                    {text: '151****9732 购买了尊享永久会员'},
                    {text: '159****8288 购买了月会员'},
                    {text: '159****8580 购买了月会员'},
                    {text: '184****7295 购买了月会员'},
                    {text: '135****5344 购买了月会员'},
                    {text: '157****9511 购买了月会员'},
                    {text: '165****6837 购买了尊享永久会员'},
                    {text: '178****8810 购买了尊享永久会员'},
                    {text: '198****1202 购买了月会员'}
                ],
                banners4: [
                    {text: '135****6485 购买了季度会员'},
                    {text: '165****0268 购买了季度会员'},
                    {text: '159****7243 购买了月会员'},
                    {text: '137****6969 购买了尊享永久会员'},
                    {text: '158****7032 购买了尊享永久会员'},
                    {text: '137****7850 购买了季度会员'},
                    {text: '150****7383 购买了季度会员'},
                    {text: '147****7091 购买了季度会员'},
                    {text: '165****2491 购买了月会员'},
                    {text: '182****4525 购买了月会员'},
                    {text: '184****9133 购买了月会员'},
                    {text: '134****3737 购买了季度会员'},
                    {text: '165****5844 购买了季度会员'},
                    {text: '138****4335 购买了月会员'},
                    {text: '184****6222 购买了月会员'},
                    {text: '188****7416 购买了尊享永久会员'},
                    {text: '157****7078 购买了月会员'},
                    {text: '178****8712 购买了月会员'},
                    {text: '184****5718 购买了PLUS年会员'},
                    {text: '151****5800 购买了PLUS年会员'},
                    {text: '198****7142 购买了月会员'},
                    {text: '157****0204 购买了月会员'},
                    {text: '184****8291 购买了PLUS年会员'},
                    {text: '135****9257 购买了月会员'},
                    {text: '136****8996 购买了月会员'},
                    {text: '147****5732 购买了月会员'},
                    {text: '134****7442 购买了月会员'},
                    {text: '198****5408 购买了PLUS年会员'}

                ],
            }
        }
        ,
        methods: {
            goToWancodeVip() {
                // const url = 'https://www.baidu.com';
                // window.open(url, '_blank');
            },
            selectIndex(index, translateY) {
                this.tabIndex = index;
                this.translateY = translateY;
            },
            studentShow() {
                this.isShow = true;
                this.studentPrise = true;
                this.teacherPrise = false;
            }
            ,
            teacherShow() {
                this.isShow = false;
                this.teacherPrise = true;
                this.studentPrise = false;
            }
            ,
            scrollToTop() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth' // 平滑滚动效果
                });
            },
        },
        created() {
            this.scrollToTop();
        },
        mounted() {
            // this.userVipType = localStorage.getItem("userVipType")
            // console.log(this.userVipType)
            const flag = this.$route.params.teacherPrise;
            // console.log(flag)
            if (flag === '1') {
                this.teacherShow()
            } else {
                this.studentShow()
            }
            this.scrollToTop();
        }
    }
</script>


<style lang="scss" scoped>

    .index_section_title {
        margin-bottom: 38px;
        //color: #000;
        text-align: center;
        font-size: 38px;
        line-height: 32px;
        font-weight: 500;
        position: relative;
        //z-index: 2;
    }

    ::v-deep .el-tabs__nav-scroll {
        width: 15% !important;
        margin: 0 auto !important;
    }

    .big-title {
        font-size: 40px;
        font-weight: 500;
        color: rgba(50, 26, 0, 1);
        line-height: 56px;
        letter-spacing: 3px;
        margin-bottom: 15px;
        margin-top: 20px;
        text-align: center;
    }

    .title {
        font-size: 36px;
        font-weight: 600;
        color: rgba(50, 26, 0, 1);
        line-height: 50px;
        margin-top: 80px;
        text-align: center;
    }


    .use-easy-one {
        width: 50%;
        text-align: center;
        margin-top: 80px;
    }


    a.join-vip {
        width: 363px;
        height: 62px;
        background-color: #2877ff;
        border-radius: 30px;
        font-size: 26px;
        /*font-weight: 600;*/
        color: white;
        line-height: 62px;
        margin-top: 20px;
        margin-bottom: 20px;
        cursor: pointer;
    }

    a.join-vip {
        width: 360px;
        height: 60px;
        line-height: 60px;
        /*background: linear-gradient(20deg, rgba(255, 150, 48, 1), rgba(255, 151, 3, 1));*/
        border-radius: 30px;
        text-align: center;
        display: block;
        margin: 60px auto 0 auto;
        font-size: 24px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
    }

    a {
        color: #666666;
        text-decoration: none;
    }

    /* --- 购买者反馈 --- */
    .message-board-total {
        width: 1200px;
        height: 300px;
        margin-top: 22px;
        border-radius: 10px;
        overflow: hidden;
        background: rgba(215, 221, 222, 1);
    }

    .banner-container {
        /*margin-left: 100px;*/
        /*margin-right: 100px;*/
        /*width: 1000px;*/
        position: relative;

        /*background:red;*/
        white-space: nowrap;

    }

    .banner {
        width: 600px;
        display: inline-block;
        padding: 20px 20px 20px 50px;
        /*background:green;*/
        animation: scroll-right 20s linear infinite;
    }

    @keyframes scroll-right {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(-100%);
        }
    }


    /* --- 价格详情 --- */
    .vip_detail_cardList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .vip_detail_item {
        width: 570px;
        height: 180px;
        background-image: url(../../assets/b2.jpg);
        margin-top: 50px;
    }

    .vip_detail_title__y {
        font-size: 30px;
        font-weight: 700;
        color: #321a00;
        padding-left: 43px;
        display: block;
        margin-top: 64px;
    }

    .vip_detail_content {
        padding-left: 43px;
        display: block;
        font-size: 18px;
        font-weight: 400;
        color: #321a00;
        margin-top: 24px;
    }


    /*具体介绍*/
    .response-container {
        width: 1200px;
        margin: 0 auto;
    }

    .w-teacher-content-bg {
        opacity: 1;
        transform: translateY(0);
    }

    .w-teacher-content-bg {
        margin-top: 64px;
        padding: 6px;
        /*background: linear-gradient(93.04deg,#fff 43.48%,#f2faff 93.1%);*/
        background: #ffffff;
        box-shadow: 0 4px 18px 1px rgb(67 105 205 / 9%);
        border-radius: 16px;
        /*transition: all 1s;*/
        /*opacity: 0;*/
        /*transform: translateY(200px);*/


        .w-teacher-content {
            height: 738px;
            background: linear-gradient(104.25deg, #f2f6fc 39.87%, #fdfeff 80.15%);
            border-radius: 10px;
            overflow: hidden;
            box-sizing: border-box;
            display: flex;

            .w-teacher-left-panel {
                flex-shrink: 0;
                width: 266px;
                padding-top: 85px;
                padding-left: 14px;
                background: linear-gradient(180deg, rgba(209, 228, 255, .4), rgba(222, 239, 255, .4));

                .tab-list {
                    position: relative;

                    /*选中的背景图*/
                    .tab-active {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 266.5px;
                        height: 114px;
                        background: url(//res.orayimg.com/sunlogin/1.0/img/79b5fb2.png) no-repeat 50%/contain;
                        z-index: 1;
                        margin-left: -18px;
                        margin-top: -14px;
                        transform: translateY(0);
                        transition: transform .1s ease-in-out;
                    }

                    .tab-item {
                        padding: 13px 24px;
                        position: relative;
                        z-index: 2;
                        cursor: pointer;

                        .tab-item__title {
                            color: #000;
                            font-size: 20px;
                            line-height: 28px;
                            font-weight: 500;
                        }

                        .tab-item__desc {
                            color: #7c7d80;
                            font-size: 14px;
                            line-height: 22px;
                            font-weight: 400;
                        }
                    }

                    .tab-item:not(:last-of-type) {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .w-teacher-right-panel {
            flex-grow: 1;
            padding: 80px 40px;

            .w-teacher-info {
                position: relative;

                .w-teacher-info__title {
                    color: #1a1a1a;
                    font-weight: 500;
                    font-size: 36px;
                    line-height: 44px;
                    display: flex;
                    align-items: center;

                    .w-teacher-info__title-link {
                        -webkit-animation: left-right-data-v-6fc4e992 2s linear infinite;
                        animation: left-right-data-v-6fc4e992 2s linear infinite;
                        margin-left: 16px;
                        width: 32px;
                        height: 32px;
                        cursor: pointer;
                    }

                }

                .w-teacher-info__hint {
                    margin-top: 13px;
                    color: #484848;
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 400;
                }

                .w-teacher-info__desc {
                    margin-top: 40px;
                    max-width: 350px;

                    .w-teacher-info__desc-item {
                        margin-top: 32px;
                        padding-left: 16px;

                        .w-teacher-info__desc-name {
                            color: #4081ff;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            position: relative;
                        }

                        .w-teacher-info__desc-hint {
                            margin-top: 12px;
                            color: #484848;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                        }


                    }
                }

                .w-teacher-info__btn {
                    margin-top: 20px;
                    width: 144px;
                    height: 34px;
                    font-size: 14px;
                    line-height: 32px;
                    font-weight: 500;
                    color: #fff;
                    background: #3773f5;
                    border-radius: 4px;
                }

                .oui-button {
                    margin-top: 20px;
                    display: inline-block;
                    white-space: nowrap;
                    cursor: pointer;
                    background: none;
                    border: 1px solid #3773f5;
                    color: #3773f5;
                    -webkit-appearance: none;
                    text-align: center;
                    box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    outline: none;
                    transition: .1s;
                    -moz-user-select: none;
                    -webkit-user-select: none;
                    -ms-user-select: none;
                    font-size: 16px;
                    height: 48px;
                    line-height: 48px;
                    min-width: 120px;
                }

                .w-teacher-info__img {
                    position: absolute;
                    right: 0;
                    top: 160px;
                    width: 460px;

                    vertical-align: middle;
                    border: 0;
                }

            }
        }
    }

    .mt-tabs-hd {
        position: relative;
        z-index: 1;
        text-align: center;
        height: 60px;
        line-height: 60px;

        .mt-tabs-list {
            border-radius: 25px;
            background-color: #fff;
            padding: 0;
            box-shadow: 0 0 60px rgb(78 106 149 / 16%);
            -webkit-backdrop-filter: none;
            backdrop-filter: none;
        }
    }


    .mt-tabs-hd .mt-tabs-list {
        display: inline-flex;
        justify-content: stretch;
        /*background: hsla(0, 0%, 100%, .3);*/
        background-color: #fff;
        box-shadow: 0 6px 9px rgb(0 103 198 / 8%);
        -webkit-backdrop-filter: blur(39px);
        backdrop-filter: blur(39px);
        border-radius: 24px;
        box-sizing: border-box;
    }

    .mt-tabs-hd a {
        border-radius: 25px;
        font-size: 20px;
        height: 48px;
        line-height: 48px;
        min-width: 164px;
        box-sizing: border-box;
    }

    .mt-tabs-hd a {
        padding: 0 30px;
        border-bottom-width: 0;
        border-radius: 24px;
        height: 48px;
        font-size: 16px;
        line-height: 48px;
        color: #000;
    }

    .mt-tabs-item a {
        display: block;
        padding-bottom: 4px;
        border-bottom: 2px solid transparent;
        font-size: 18px;
        line-height: 24px;
        color: #1d1e21;
        cursor: pointer;

        background-color: transparent;
        text-decoration: none;
        color: #06f;
        cursor: pointer;
    }


    .mt-tabs-item.is-active a {
        font-weight: 500;
    }

    .mt-tabs-hd a {
        border-radius: 25px;
        font-size: 20px;
        height: 48px;
        line-height: 48px;
        min-width: 164px;
        box-sizing: border-box;
    }

    .mt-tabs-hd .is-active a {
        background: #0b6cfe;
        color: #fff;
    }

    .mt-tabs-hd a {
        padding: 0 30px;
        border-bottom-width: 0;
        border-radius: 24px;
        height: 48px;
        font-size: 18px;
        line-height: 48px;
        color: #000;
    }

</style>
