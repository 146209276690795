var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"member-vip-list"},[(_vm.vipType.type ===4)?_c('div',{staticClass:"member-vip"},[_c('div',{staticClass:"top-box"},[_c('h4',[_vm._v("月会员")]),_c('div',{staticClass:"member-vip-panel"},[_vm._m(0),_c('p',{staticClass:"desc"},[_vm._v("全站试题免费作答，试卷每日15次免费作答")]),_c('button',{staticClass:"upgrade-btn upgrade-plus",on:{"click":_vm.getVip}},[_vm._v("立即开通 ")])])]),_vm._m(1)]):_vm._e(),(_vm.vipType.type ===7)?_c('div',{staticClass:"member-vip"},[_c('div',{staticClass:"top-box"},[_c('h4',[_vm._v("季度会员")]),_c('div',{staticClass:"member-vip-panel"},[_vm._m(2),_c('p',{staticClass:"desc"},[_vm._v("全站试题免费作答，试卷每日20次免费作答")]),_c('button',{staticClass:"upgrade-btn upgrade-plus",on:{"click":_vm.getVip}},[_vm._v("立即开通 ")])])]),_vm._m(3)]):_vm._e(),(_vm.vipType.type ===5)?_c('div',{staticClass:"member-vip"},[_c('div',{staticClass:"top-box"},[_c('h4',[_vm._v("PLUS年会员")]),_c('div',{staticClass:"member-vip-panel"},[_vm._m(4),_c('p',{staticClass:"desc"},[_vm._v("全站试题免费作答，试卷每日30次免费作答")]),_c('button',{staticClass:"upgrade-btn upgrade-plus",on:{"click":_vm.getVip}},[_vm._v("立即开通 ")])])]),_vm._m(5)]):_vm._e(),(_vm.vipType.type ===1)?_c('div',{staticClass:"member-vip"},[_c('div',{staticClass:"top-box"},[_c('h4',[_vm._v("尊享永久会员")]),_c('div',{staticClass:"member-vip-panel"},[_vm._m(6),_c('p',{staticClass:"desc"},[_vm._v("全站试题免费作答，试卷每日30次免费作答")]),_c('button',{staticClass:"upgrade-btn upgrade-plus",on:{"click":_vm.getVip}},[_vm._v("立即开通 ")])])]),_vm._m(7)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('span',{staticClass:"price-prev"}),_c('span',{staticClass:"price"},[_vm._v("49")]),_c('span',{staticClass:"price-unit"},[_vm._v("元/月")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-box"},[_c('div',[_c('h5',[_c('i',{staticClass:"icon el-icon-document-copy"}),_c('span',[_vm._v("内容服务")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt",staticStyle:{"width":"70%","text-align":"left"}},[_vm._v("免费和VIP试卷")]),_c('b',{staticClass:"item_select"},[_vm._v("15次/天/账号")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("有效期")]),_c('b',{staticClass:"item_select"},[_vm._v("30天")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("题库答题")]),_c('b',{staticClass:"primary-color6 item_select"},[_vm._v("无限次")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("查看试题答案/解析")]),_c('b',{staticClass:"primary-color6 item_select"},[_vm._v("无限次")])])]),_c('div',[_c('h5',[_c('img',{attrs:{"src":require("@/assets/vip.svg")}}),_c('span',[_vm._v("尊享服务")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("VIP专享文档")]),_c('b',{staticClass:"el-icon-check icon-right"})]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("试卷下载")]),_c('b',{staticClass:"el-icon-close icon-right"})]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("精品专辑")]),_c('b',{staticClass:"el-icon-close icon-right"})]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("专员录题/组卷")]),_c('b',{staticClass:"el-icon-close icon-right"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('span',{staticClass:"price-prev"}),_c('span',{staticClass:"price"},[_vm._v("129")]),_c('span',{staticClass:"price-unit"},[_vm._v("元/季")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-box"},[_c('div',[_c('h5',[_c('i',{staticClass:"icon el-icon-document-copy"}),_c('span',[_vm._v("内容服务")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt",staticStyle:{"width":"70%","text-align":"left"}},[_vm._v("免费和VIP试卷")]),_c('b',{staticClass:"item_select"},[_vm._v("20次/天/账号")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("有效期")]),_c('b',{staticClass:"item_select"},[_vm._v("95天")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("题库答题")]),_c('b',{staticClass:"primary-color6 item_select"},[_vm._v("无限次")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("查看试题答案/解析")]),_c('b',{staticClass:"primary-color6 item_select"},[_vm._v("无限次")])])]),_c('div',[_c('h5',[_c('img',{attrs:{"src":require("@/assets/vip.svg")}}),_c('span',[_vm._v("尊享服务")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("VIP专享文档")]),_c('b',{staticClass:"el-icon-check icon-right"})]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("试卷下载")]),_c('b',{staticClass:"el-icon-check icon-right"})]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("精品专辑")]),_c('b',{staticClass:"el-icon-check icon-right"})]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("专员录题/组卷")]),_c('b',{staticClass:"el-icon-close icon-right"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('span',{staticClass:"price-prev"}),_c('span',{staticClass:"price"},[_vm._v("269")]),_c('span',{staticClass:"price-unit"},[_vm._v("元/年")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-box"},[_c('div',[_c('h5',[_c('i',{staticClass:"icon el-icon-document-copy"}),_c('span',[_vm._v("内容服务")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt",staticStyle:{"width":"70%","text-align":"left"}},[_vm._v("免费和VIP试卷")]),_c('b',{staticClass:"item_select"},[_vm._v("30次/天/账号")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("有效期")]),_c('b',{staticClass:"item_select"},[_vm._v("一年")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("题库答题")]),_c('b',{staticClass:"primary-color6 item_select"},[_vm._v("无限次")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("查看试题答案/解析")]),_c('b',{staticClass:"primary-color6 item_select"},[_vm._v("无限次")])])]),_c('div',[_c('h5',[_c('img',{attrs:{"src":require("@/assets/vip.svg")}}),_c('span',[_vm._v("尊享服务")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("VIP专享文档")]),_c('b',{staticClass:"el-icon-check icon-right"})]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("试卷下载")]),_c('b',{staticClass:"el-icon-check icon-right"})]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("精品专辑")]),_c('b',{staticClass:"el-icon-check icon-right"})]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("专员录题/组卷")]),_c('b',{staticClass:"el-icon-check icon-right"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('span',{staticClass:"price-prev"}),_c('span',{staticClass:"price"},[_vm._v("1999")]),_c('span',{staticClass:"price-unit"},[_vm._v("元/永久")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-box"},[_c('div',[_c('h5',[_c('i',{staticClass:"icon el-icon-document-copy"}),_c('span',[_vm._v("内容服务")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt",staticStyle:{"width":"70%","text-align":"left"}},[_vm._v("免费和VIP试卷")]),_c('b',{staticClass:"item_select"},[_vm._v("30次/天/账号")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("有效期")]),_c('b',{staticClass:"item_select"},[_vm._v("永久有效")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("题库答题")]),_c('b',{staticClass:"primary-color6 item_select"},[_vm._v("无限次")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("查看试题答案/解析")]),_c('b',{staticClass:"primary-color6 item_select"},[_vm._v("无限次")])])]),_c('div',[_c('h5',[_c('img',{attrs:{"src":require("@/assets/vip.svg")}}),_c('span',[_vm._v("尊享服务")])]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("VIP专享文档")]),_c('b',{staticClass:"el-icon-check icon-right"})]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("试卷下载")]),_c('b',{staticClass:"el-icon-check icon-right"})]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("精品专辑")]),_c('b',{staticClass:"el-icon-check icon-right"})]),_c('div',{staticClass:"box_item"},[_c('span',{staticClass:"item_txt"},[_vm._v("专员录题/组卷")]),_c('b',{staticClass:"el-icon-check icon-right"})])])])
}]

export { render, staticRenderFns }