<template>
    <div class="member-vip-list">
        <div class="member-vip" v-if="vipType.type ===4">
            <div class="top-box">
                <h4>月会员</h4>
                <div class="member-vip-panel">
                    <strong>
                        <span class="price-prev"></span>
                        <span class="price">199</span>
                        <span class="price-unit">元/月</span>
                    </strong>
                    <p class="desc">全站试题免费作答，试卷每日15次免费作答</p>
                    <button class="upgrade-btn upgrade-plus" @click="getTeacherVip">开通教师会员
                    </button>
                </div>
            </div>
            <div class="bottom-box">
                <div>
                    <h5><i class="icon el-icon-document-copy"></i><span>内容服务</span></h5>
                    <div class="box_item">
                        <span class="item_txt" style="width: 70%;text-align: left;">免费和VIP试卷</span>
                        <b class="item_select">15次/天/账号</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt" style="width: 70%;text-align: left;">教师账号</span>
                        <b class="item_select">1个</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt" style="width: 70%;text-align: left;">学生账号</span>
                        <b class="item_select">5个</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">教师会员有效期</span>
                        <b class="item_select">30天</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">学生会员有效期</span>
                        <b class="item_select">30天</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">题库答题</span>
                        <b class="primary-color6 item_select">无限次</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">查看试题答案/解析</span>
                        <b class="primary-color6 item_select">无限次</b>
                    </div>
                </div>
                <div>
                    <h5><img src="@/assets/vip.svg"><span>尊享服务</span></h5>
                    <div class="box_item">
                        <span class="item_txt">教师和学生账号全站均可作答</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">VIP专享文档</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">试卷下载</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">精品专辑</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">录题/组卷</span>
                        <b class="el-icon-close icon-right"></b>
                    </div>

                </div>
            </div>
        </div>
        <div class="member-vip" v-if="vipType.type ===7">
            <div class="top-box">
                <h4>季度会员</h4>
                <div class="member-vip-panel">
                    <strong>
                        <span class="price-prev"></span>
                        <span class="price">399</span>
                        <span class="price-unit">元/季</span>
                    </strong>
                    <p class="desc">全站试题免费作答，试卷每日20次免费作答</p>
                    <button class="upgrade-btn upgrade-plus" @click="getTeacherVip">开通教师会员
                    </button>
                </div>
            </div>
            <div class="bottom-box">
                <div>
                    <h5><i class="icon el-icon-document-copy"></i><span>内容服务</span></h5>
                    <div class="box_item">
                        <span class="item_txt" style="width: 70%;text-align: left;">免费和VIP试卷</span>
                        <b class="item_select">20次/天/账号</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt" style="width: 70%;text-align: left;">教师账号</span>
                        <b class="item_select">1个</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt" style="width: 70%;text-align: left;">学生账号</span>
                        <b class="item_select">10个</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">教师会员有效期</span>
                        <b class="item_select">3个月</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">学生会员有效期</span>
                        <b class="item_select">3个月</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">题库答题</span>
                        <b class="primary-color6 item_select">无限次</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">查看试题答案/解析</span>
                        <b class="primary-color6 item_select">无限次</b>
                    </div>
                    <!--                    <div class="box_item">-->
                    <!--                        <span class="item_txt">考试结果回顾</span>-->
                    <!--                        <b class="primary-color6 item_select">无限次</b>-->
                    <!--                    </div>-->
                    <!--                    <div class="box_item">-->
                    <!--                        <span class="item_txt">错题记录查阅</span>-->
                    <!--                        <b class="primary-color6 item_select">无限次</b>-->
                    <!--                    </div>-->

                </div>
                <div>
                    <h5><img src="@/assets/vip.svg"><span>尊享服务</span></h5>
                    <div class="box_item">
                        <span class="item_txt">教师和学生账号全站均可作答</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">VIP专享文档</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">试卷下载</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">精品专辑</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">录题/组卷</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>

                </div>
            </div>
        </div>
        <div class="member-vip" v-if="vipType.type ===5">
            <div class="top-box">
                <h4>PLUS年会员</h4>
                <div class="member-vip-panel">
                    <strong>
                        <span class="price-prev"></span>
                        <span class="price">999</span>
                        <span class="price-unit">元/年</span>
                    </strong>
                    <p class="desc">全站试题免费作答，试卷每日30次免费作答</p>
                    <button class="upgrade-btn upgrade-plus" @click="getTeacherVip">开通教师会员
                    </button>
                </div>
            </div>
            <div class="bottom-box">
                <div>
                    <h5><i class="icon el-icon-document-copy"></i><span>内容服务</span></h5>
                    <div class="box_item">
                        <span class="item_txt" style="width: 70%;text-align: left;">免费和VIP试卷</span>
                        <b class="item_select">30次/天/账号</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt" style="width: 70%;text-align: left;">教师账号</span>
                        <b class="item_select">2个</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt" style="width: 70%;text-align: left;">学生账号</span>
                        <b class="item_select">50个</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">教师会员有效期</span>
                        <b class="item_select">一年</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">学生会员有效期</span>
                        <b class="item_select">一年</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">题库答题</span>
                        <b class="primary-color6 item_select">无限次</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">查看试题答案/解析</span>
                        <b class="primary-color6 item_select">无限次</b>
                    </div>
                    <!--                    <div class="box_item">-->
                    <!--                        <span class="item_txt">考试结果回顾</span>-->
                    <!--                        <b class="primary-color6 item_select">无限次</b>-->
                    <!--                    </div>-->
                    <!--                    <div class="box_item">-->
                    <!--                        <span class="item_txt">错题记录查阅</span>-->
                    <!--                        <b class="primary-color6 item_select">无限次</b>-->
                    <!--                    </div>-->


                </div>
                <div>
                    <h5><img src="@/assets/vip.svg"><span>尊享服务</span></h5>
                    <div class="box_item">
                        <span class="item_txt">教师和学生账号全站均可作答</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">VIP专享文档</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">试卷下载</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">精品专辑</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">录题/组卷</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>

                </div>
            </div>
        </div>
        <div class="member-vip" v-if="vipType.type ===6">
            <div class="top-box">
                <h4>尊享三年会员</h4>
                <div class="member-vip-panel">
                    <strong>
                        <span class="price-prev"></span>
                        <span class="price">1999</span>
                        <span class="price-unit">元/3年</span>
                    </strong>
                    <p class="desc">全站试题免费作答，试卷每日30次免费作答</p>
                    <button class="upgrade-btn upgrade-plus" @click="getTeacherVip">开通教师会员
                    </button>
                </div>
            </div>
            <div class="bottom-box">
                <div>
                    <h5><i class="icon el-icon-document-copy"></i><span>内容服务</span></h5>
                    <div class="box_item">
                        <span class="item_txt" style="width: 70%;text-align: left;">免费和VIP试卷</span>
                        <b class="item_select">30次/天/账号</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt" style="width: 70%;text-align: left;">教师账号</span>
                        <b class="item_select">5个</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt" style="width: 70%;text-align: left;">学生账号</span>
                        <b class="item_select">200个</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">教师会员有效期</span>
                        <b class="item_select">三年</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">学生会员有效期</span>
                        <b class="item_select">三年</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">题库答题</span>
                        <b class="primary-color6 item_select">无限次</b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">查看试题答案/解析</span>
                        <b class="primary-color6 item_select">无限次</b>
                    </div>
                    <!--                    <div class="box_item">-->
                    <!--                        <span class="item_txt">考试结果回顾</span>-->
                    <!--                        <b class="primary-color6 item_select">无限次</b>-->
                    <!--                    </div>-->
                    <!--                    <div class="box_item">-->
                    <!--                        <span class="item_txt">错题记录查阅</span>-->
                    <!--                        <b class="primary-color6 item_select">无限次</b>-->
                    <!--                    </div>-->


                </div>
                <div>
                    <h5><img src="@/assets/vip.svg"><span>尊享服务</span></h5>
                    <div class="box_item">
                        <span class="item_txt">教师和学生账号全站均可作答</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">VIP专享文档</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">试卷下载</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">精品专辑</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>
                    <div class="box_item">
                        <span class="item_txt">录题/组卷</span>
                        <b class="el-icon-check icon-right"></b>
                    </div>

                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import {
        openTeacherVip
    } from "@/api/index.js"

    export default {
        filters: {
            formatType(type) {
                switch (type) {
                    case 1:
                        return "会员"
                        break;
                    case 2:
                        return "会员"
                        break;
                    case 3:
                        return "会员-周"
                        break;
                    case 4:
                        return "会员-月"
                        break;
                    case 5:
                        return "会员-年"
                        break;
                    case 6:
                        return "会员-3年"
                        break;
                    case 7:
                        return "会员-3月"
                        break;
                    default:
                        break;
                }
            }
        },
        props: {
            isRecommend: {
                type: String,
                default: 'selfVipCard'
            },
            vipType: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                userVipType: null,
                userId: 1,
                radio1: 'Scratch',
                value: '1',
                options: [{
                    value: '1',
                    label: '一级'
                }, {
                    value: '2',
                    label: '二级'
                }, {
                    value: '3',
                    label: '三级'
                }, {
                    value: '4',
                    label: '四级'
                }]
            }
        },
        mounted() {
            this.userVipType = this.vipType.type
            this.userId = localStorage.getItem("userId")
        },
        methods: {
            getTeacherVip() {
                let param = {
                    userId: Number(this.userId),
                    userVipType: this.userVipType.toString(),
                    name: this.radio1,
                    level: this.value
                }
                openTeacherVip(param).then(res => {
                    if (res.code === "0000") {
                        const div = document.createElement('div');
                        /* 此处form就是后台返回接收到的数据 */
                        div.innerHTML = res.data;
                        document.body.appendChild(div);
                        document.forms[0].setAttribute('target', '_blank'); // 新开窗口跳转
                        document.forms[0].acceptCharset = 'utf-8';
                        document.forms[0].submit();
                    }
                    location.reload()
                })
            },
        }
    }
</script>

<style>
    .bottom-box .box_item {
        line-height: 34px;
        font-size: 0;
        color: #000;
    }

    .box_item > .item_txt {
        width: 70%;
        text-align: left;
    }

    .box_item > .item_select,
    .box_item .icon-right {
        width: 30%;
        text-align: right;
        font-weight: normal;
    }

</style>

<style lang="scss" scoped>
    h3 {
        font-size: 26px;
        text-align: center;
        margin: 25px 0 13px;
        font-weight: bold;
        color: rgba(50, 26, 0, 1);
        line-height: 35px;
        letter-spacing: 2px;
        padding-top: 20px;
    }

    .orange {
        color: #CC7400;
    }

    .price {
        height: 70px;
        font-size: 50px;
        text-align: center;
        margin-bottom: 20px;
    }

    .sears .price span {
        font-size: 16px;
        color: #321A00;
        font-weight: 600;
        position: relative;
    }

    .price .year {
        vertical-align: 1px;
        margin-left: 3px;
    }

    .selfVipCard {
        width: 300px;
        height: 600px;
        background-color: #FFFFFF;
        overflow: hidden;
        position: relative;
        border-radius: 5px;
        box-shadow: 2px 2px 2px #64390F;
        border: 1px solid #FFFFFF;
        transition: all .1s ease-in;
    }

    .selfVipCard:hover {
        border: 3px solid #64390F;
        // border-top: 2px solid #FF69B4;
        transform: translateY(-10px);
        box-sizing: border-box;
        box-shadow: 5px 5px 2px #64390F;
    }

    .recommend::before {
        content: "";
        position: absolute;
        width: 30px;
        height: 180px;
        background-color: #64390F;
        transform: rotate(45deg);
        top: -50px;
    }

    .recommend::after {
        content: "推 荐";
        color: #FFFFFF;
        transform: rotate(-45deg);
        width: 50px;
        height: 30px;
        left: 8px;
        top: 12px;
        position: absolute;
        font-weight: 900;
        font-size: 18px;
        // margin-top: -20px;
    }

    .origin-price-show {
        opacity: 1;
        font-size: 16px;
        color: #A5A5A5;
        width: 80%;
    }

    .iconfont {
        color: #FF0000;
        font-size: 18px;
        position: relative;
    }

    .limit-c {
        // float: right;
        color: #FF0000;
        font-size: 12px;
        line-height: 22px;
    }

    * {
        margin: 0;
        padding: 0;
    }

    .member-vip-list {
        display: flex;
        margin-top: 40px;

        .member-vip {
            width: 380px;
            background-color: #fff;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            flex: 1;
            border-radius: 12px;
            margin-right: 25px;
            position: relative;


            .top-box {
                width: 100%;
                height: 298px;
                box-sizing: border-box;
                padding-top: 30px;
                text-align: center;
                border-radius: 12px 12px 0 0;
                /*background: #c0ccda;*/
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.28) 0, rgba(235, 235, 235, 0.28) 100%);
                /*background: linear-gradient(180deg, rgba(255, 255, 255, 0.28) 0, rgba(235, 235, 235, 0.28) 100%);*/
                /*background: url(https://static.zxxk.com/zujuan/pay/static/assets/member_img.png) no-repeat 0 0;*/


                h4 {
                    display: inline-block;
                    font-family: PingFangSC-Semibold, Microsoft YaHei, Arial, Helvetica, sans-serif;
                    margin: 0 0 36px 0;
                    font-size: 28px;
                    text-align: center;
                    line-height: 1;
                    font-weight: 600;

                    background: linear-gradient(240deg, #332100 0, #9a5a00 72%);
                    /*<!--text-shadow: 0 1px 1px rgb(0 0 0 / 18%);-->*/
                    /*text-shadow: 0 1px rgba(0,0,0, 0.5);*/
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;

                }

                .member-vip-panel {
                    font-family: PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
                    font-size: 16px;
                    font-weight: normal;

                    width: 100%;
                    height: 204px;
                    text-align: center;

                    .price {
                        color: #994b00;
                        font-size: 56px;
                        font-weight: normal;
                    }

                    .price-unit {
                        color: #994b00;
                        font-weight: normal;
                    }

                    .desc {
                        font-size: 16px;
                        margin-top: 10px;
                        margin-bottom: 30px;
                    }

                    .upgrade-btn {
                        /*background: #2877ff;*/
                        background: #ff7f14;
                        /*<!--box-shadow: 0 2px 6px 0 rgb(0 27 119 / 20%);-->*/
                        color: white;

                        width: 320px;
                        height: 48px;
                        font-size: 16px;
                        font-family: PingFangSC-Semibold, Microsoft YaHei, Arial, Helvetica, sans-serif;
                        font-weight: 600;
                        line-height: 3;
                        cursor: pointer;
                        border-radius: 24px;
                        box-sizing: border-box;
                        border: 0;
                        outline: 0;
                    }
                }
            }


            .bottom-box {
                box-sizing: border-box;
                padding: 28px;

                div > h5 {
                    height: 18px;
                    margin-bottom: 16px;
                    font-size: 16px;
                    font-weight: normal;
                    color: #994b00;


                    display: block;
                    margin-block-start: 1.67em;
                    /*margin-block-end: 1.67em;*/
                    margin-inline-start: 0px;
                    margin-inline-end: 0px;

                    img {
                        float: left;
                        margin-top: 2px;
                        padding-right: 2px;
                        width: auto;
                        height: auto;
                        max-width: 110%;
                        max-height: 110%;
                    }
                }


                div > h5 > i {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 4px;
                }

                .primary-color6 {
                    color: #29f;
                }

            }

            .bottom-box .box_item > .item_txt,
            .bottom-box .box_item > b {
                display: inline-block;
                vertical-align: middle;
                font-size: 14px;
            }
        }
    }
</style>
